export default function pressAccordion() {
	var acc = document.getElementsByClassName("press-accordion__section");
	var i;

	for (i = 0; i < acc.length; i++) {
		acc[i].addEventListener("click", function () {
			this.classList.toggle("active");
			var panel = this.nextElementSibling;
			var chevron = this.querySelector(".press-accordion__chevron svg");
			panel.classList.toggle("active");

			if (panel.style.maxHeight) {
				panel.style.maxHeight = null;
				chevron.classList.toggle("rotate-180");
			} else {
				panel.style.maxHeight = panel.scrollHeight + "px";
				chevron.classList.toggle("rotate-180");
			}
		});
	}

	window.addEventListener("resize", function () {
		var content = document.getElementsByClassName(
			"press-accordion__content active",
		);

		for (i = 0; i < content.length; i++) {
			content[i].style.maxHeight = content[i].scrollHeight + "px";
		}
	});
}
