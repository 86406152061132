import Swiper from "swiper";
import { Navigation } from "swiper/modules";
import { BookSwiper } from "./BookSwiper";

Swiper.use([Navigation]);

export default function slider() {
	const $swipers = document.querySelectorAll(".swiper-container");
	$swipers.forEach(initSlider);
}

function initSlider($swiper) {
	const $next = $swiper.querySelector(".swiper-button-next");
	const $prev = $swiper.querySelector(".swiper-button-prev");

	let config = {
		slidesPerView: 3,
		spaceBetween: 10,
		watchOverflow: true,
		navigation: {
			nextEl: $next,
			prevEl: $prev,
		},
		breakpoints: {
			0: {
				slidesPerView: 2,
			},
			480: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 5,
			},
		},
	};

	if ($swiper.dataset.type === "card") {
		config.slidesPerView = 1;
		config.breakpoints["480"].slidesPerView = 2;
	}

	const s = new Swiper($swiper, config);

	const updateRealNextSlide = (_) => {
		let $nextCard = s.slides[s.activeIndex + s.slidesPerViewDynamic()];

		Array.from(s.slides).forEach(($slide) =>
			$slide.classList.remove("swiper-slide-real-next"),
		);

		if ($nextCard) {
			$nextCard.classList.add("swiper-slide-real-next");
		}
	};

	updateRealNextSlide();

	s.on("slideChange", function () {
		updateRealNextSlide();
	});

	if ($swiper.dataset.type === "book") {
		let bs = new BookSwiper($swiper, s);

		if (window.innerWidth >= 768) {
			bs.activate();
		}

		s.on("resize", function () {
			let desktopish = window.innerWidth >= 768;
			if (desktopish && !bs.running) {
				bs.activate();
			} else if (!desktopish && bs.running) {
				bs.deactivate();
			}
		});
	}
}
