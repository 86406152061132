import Choices from "choices.js";

export default () => {
	const $locForm = document.querySelector("[data-location-changer]");
	if (!$locForm) return;
	const $locSelect = $locForm.querySelector("select");
	new Choices($locSelect, {
		searchEnabled: false,
		itemSelectText: "",
	});
	$locSelect.addEventListener("change", () => {
		let formData = new FormData($locForm);
		fetch(window.location.toString(), {
			method: $locForm.method,
			body: formData,
		}).then((_) => {
			window.location.reload();
		});
	});
};
