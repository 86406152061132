import { AjaxForm } from "@neonjungle/birdseed/forms/ajax";
import swiper from "./swiper";

class SearchForm extends AjaxForm {
	afterFetch(responseJson) {
		super.afterFetch();
		// Reinitalise any sliders
		swiper();
		// Adding the history to the URL. TODO make function on AjaxForm for creating querystring
		const formData = new FormData(this.form);
		const parameters = Array.from(formData.entries())
			.map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
			.join("&");
		const pushedURL = `${this.form.action}?${parameters}`;
		history.pushState({ formData: formData }, "", pushedURL);
	}

	setupListeners() {
		super.setupListeners();
		this.form.querySelectorAll("[data-ajax-change]").forEach((input) => {
			let debounceTimer;
			input.addEventListener(
				"input",
				(e) => {
					clearTimeout(debounceTimer);
					debounceTimer = setTimeout(() => {
						this.fetchAndReplace();
					}, 300);
				},
				false,
			);
		});
	}

	getFetchOptions(formMethod) {
		let fetchOptions = super.getFetchOptions(formMethod);
		fetchOptions.cache = "no-store";
		return fetchOptions;
	}
}

export default function initSearchForm() {
	document.querySelectorAll(".index-form[data-ajax-form]").forEach((form) => {
		let target = document.querySelector("[data-ajax-target]");
		new SearchForm(form, target);
	});
}
