function dropdownActions(element, closed) {
	const groupEl = element.querySelector(".award-year__groups");
	const height = groupEl.offsetHeight;
	if (closed) {
		element.classList.add("closed");
	}
	const control = element.querySelector(".award-year__title");
	control.addEventListener("click", (e) => {
		e.preventDefault();
		element.classList.toggle("closed");
		if (element.classList.contains("closed")) {
			groupEl.style.maxHeight = "0px";
		} else {
			groupEl.style.maxHeight = `${height}px`;
		}
	});
}

export default (_) => {
	document.querySelectorAll("[data-year-dropdown]").forEach((el) => {
		const closed = el.dataset["yearDropdown"] == "closed";
		dropdownActions(el, closed);
	});
};
